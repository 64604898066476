<template lang="pug">
div(
  v-if='refinements.length > 0'
  class='mb-3 rounded border border-solid cursor-pointer overflow-hidden'
  :class='showHightlightWhenRefined && selectedRefinements ? "border-info" : "border-gray-lighter"'
)
  div(class='py-2 px-4 text-dark font-bold' :class='isLight ? "bg-white" : "bg-gray-lighter"' @click='isCollapsed = !isCollapsed')
    div(
      class='after:content-["-"] after:block after:text-[20px] uppercase flex justify-between items-center'
      :class='isCollapsed && collapsedClass'
    ) {{ title }}
    div(
      v-if='showSelectedRefinementsWhenCollapsed && isCollapsed && selectedRefinements'
      class='font-normal text-sm text-gray transform-none truncate'
    ) {{ selectedRefinements }}

  ul(
    v-show='!isCollapsed'
    class='lg:max-h-[500px] pt-1 px-4 pb-2 bg-white text-sm lg:pt-2 lg:overflow-x-hidden lg:overflow-y-auto'
  )
    template(v-for='refinement in refinements' :key='refinement.value')
      li(class='lg:mb-[15px] mb-[18px] last:mb-0')
        slot(v-bind='refinement')
          div(:class='refinement.isRefined && refinedClass')
            label(
              class='flex items-center before:w-[20px] before:h-[20px] before:rounded-[3px] before:border-2 before:border-solid before:border-gray-dark before:mr-[10px] before:flex-grow-0 before:flex-shrink-0 before:flex-auto'
            )
              input(type='checkbox' :checked='refinement.isRefined' class='hidden' @change='toggleFacet(refinement)')
              slot(name='display' :display='refinement.display')
                | {{ refinement.display }}
</template>

<script setup lang="ts">
const route = useRoute()
const $searchContext = inject('$searchContext', null)
const emit = defineEmits(['refinementClicked'])

const {
  attribute,
  title,
  values,
  isLight,
  showHightlightWhenRefined,
  isCollapsedDefault,
  showSelectedRefinementsWhenCollapsed,
} = defineProps<{
  attribute?: string
  title: string
  values?: any[]
  isLight?: boolean
  showHightlightWhenRefined?: boolean
  isCollapsedDefault?: boolean
  showSelectedRefinementsWhenCollapsed?: boolean
}>()

const isCollapsed = ref(isCollapsedDefault)

const refinements = computed(() => {
  if (values) return values

  const refinements = $searchContext.state.value.refinements[attribute] || []
  return refinements.filter(({ isValid }) => isValid)
})

const selectedRefinements = computed(() => {
  return refinements.value
    .filter(({ isRefined }) => isRefined)
    .map(({ display }) => display)
    .join(', ')
})

const collapsedClass = computed(() => {
  return "after:content-['+']"
})

const refinedClass = computed(() => {
  return "before:[&>label]:bg-action before:[&>label]:border-action before:[&>label]:bg-[url('/images/checkmarkThick.svg')] before:[&>label]:bg-[size:12px] before:[&>label]:bg-no-repeat before:[&>label]:bg-center"
})

function toggleFacet(refinement: any) {
  const { value, isRefined } = refinement

  if (isRefined) $searchContext.removeRefinements(attribute, value)
  else $searchContext.addRefinements(attribute, value)

  const { mod } = route.query

  const routeObj = {
    name: route.name,
    params: {
      ...route.params,
    },
    query: {
      ...(mod && { mod }),
      ...$searchContext.createRouteQuery(),
    },
  }

  emit('refinementClicked', {
    attribute: attribute,
    title: title,
    value,
  })

  navigateTo(routeObj)
}
</script>
