<template lang="pug">
div
  //- Only display if facet has refinements or Category exists
  button(
    v-if='refinements.length > 0 || data.title'
    type='button'
    class='h-14 flex justify-between items-center gap-x-2 w-full text-black text-left'
    @click='openMenu'
  )
    div(class='w-3/4')
      p(class='truncate')
        slot(name='title')
      div(v-if='selectedRefinements' class='font-normal text-sm text-gray transform-none truncate') {{ selectedRefinements }}
    ChevronRightIcon(class='flex-none mr-3 w-6 h-6 stroke-current')

  transition(
    enter-active-class='right-0 transition ease-in-out duration-100 transform'
    enter-from-class='right-0 translate-x-full'
    enter-to-class='right-0'
    leave-active-class='right-0 transition ease-in-out duration-100 transform'
    leave-from-class='right-0'
    leave-to-class='translate-x-full'
  )
    div(v-if='isMenuOpen' class='fixed top-0 bottom-0 bg-white w-[404px] md:w-[480px] pointer-events-auto' style='right: 0')
      button(
        type='button'
        class='flex items-center justify-start gap-x-1 pl-3 pr-5 h-12 w-full bg-gray-lighter border-b border-gray-light font-bold uppercase text-black text-base'
        @click='closeMenu'
      )
        ChevronLeftIcon(class='flex-none w-6 h-6 stroke-current')
        p(class='text-left truncate ml-3')
          slot(name='title')

      div(class='overflow-y-auto h-[calc(100vh-56px)] pb-36 space-y-4')
        slot(name='content')
</template>

<script setup lang="ts">
import ChevronRightIcon from '@/assets/chevronRight.svg?component'
import ChevronLeftIcon from '@/assets/chevronLeft.svg?component'
const emit = defineEmits(['onFilterSubMenuClose'])
const { $algolia } = useNuxtApp()
const { data, filterSubMenu } = defineProps<{
  data: any
  filterSubMenu?: string // String name of the sub menu that is currently open; taken from the catalog data facet.text, Possible values 'Category' or a facet.text value
}>()

const showMenu = ref(false)

defineExpose({
  closeMenu,
})

const refinements = computed(() => {
  const refinements = $algolia.searchContexts.catalog.state.value.refinements[data.facet] || []
  return refinements.filter(({ isValid }) => isValid)
})

const selectedRefinements = computed(() => {
  return refinements.value
    .filter(({ isRefined }) => isRefined)
    .map(({ display }) => display)
    .join(', ')
})

const isMenuOpen = computed(() => {
  // Check if user changed the filterSubMenu by clicking a toolbar button
  // We need to check the category and facet data separately
  return (
    filterSubMenu === data.title || // Check category
    filterSubMenu === data.text || // Check facet
    showMenu.value
  )
})

function openMenu() {
  showMenu.value = true
}
function closeMenu() {
  showMenu.value = false
  emit('onFilterSubMenuClose')
}
</script>
