<template lang="pug">
div(class='whitespace-nowrap font-bold text-dark')
  span(class='hidden lg:inline') {{ resultStart }} - {{ resultEnd }} of&nbsp;
  | {{ resultCount }}
  template(v-if='resultCount >= 1000') +
  | &nbsp;{{ resultLabel }}
</template>

<script setup lang="ts">
const $searchContext = inject('$searchContext', null)

const results = computed(() => {
  return $searchContext.state.value.results
})

const resultCount = computed(() => {
  if ($searchContext.state.value.resultCount > 1000) return 1000
  return $searchContext.state.value.resultCount
})

const resultLabel = computed(() => {
  return resultCount.value === 1 ? 'result' : 'results'
})

const resultStart = computed(() => {
  return ($searchContext.state.value.currentPage - 1) * $searchContext.state.value.searchParameters.resultsPerPage + 1
})

const resultEnd = computed(() => {
  return resultStart.value + $searchContext.state.value.results.length - 1
})
</script>
