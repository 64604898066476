<template lang="pug">
div(v-if='data')
  AlgoliaWrapper(:search-context='searchContext')
    AlgoliaResults
      CmsStoryblok(v-if='data.story?.content?.topSections' :content='data.story.content' section-key='topSections')

    div(class='p-3')
      div(class='lg:container')
        Crumbs(v-if='data.crumbs' :crumbs='data.crumbs')

        div(v-if='!hideProducts' class='overflow-auto')
          FitmentInline(
            class='mt-4 -mx-3 lg:mt-6 lg:mx-0 bg-white lg:bg-gray-lighter'
            :class='isCategoryFilterAB ? "mb-4 lg:mb-6" : ""'
          )

        h1(v-if='!disableH1' id='catalog-title' class='text-center sm:text-left' data-testid='catalogTitle')
          span(v-if='fitmentStore.fitmentFormatted' class='block text-gray-dark text-xl') {{ fitmentStore.fitmentFormatted }}&nbsp;
          InlineHtml(class='block' :text='h1')

    div(v-if='!hideProducts' class='px-3 pb-6')
      div(class='lg:container')
        div(ref='scrollTopEl' class='flex flex-wrap -mx-3')
          //- A/B Category filter update - do not show Refinements overlay in test
          div(v-if='!isCategoryFilterAB' class='px-3 w-full lg:w-1/5')
            CatalogRefinements(:catalog-data='data')

          div(class='px-3 flex-1')
            //- A/B Category filter update - hide original in test
            template(v-if='!isCategoryFilterAB')
              CatalogRefinementsSelections(v-if='$device.value.isDesktop' :catalog-data='data')

              //- On Desktop we only want the toolbar to show when we have results
              template(v-if='$device.value.isDesktop')
                AlgoliaResults
                  template(#results)
                    CatalogToolbar(:is-category-filter-a-b='isCategoryFilterAB' :catalog-data='data')

              template(v-else)
                CatalogToolbar(:is-category-filter-a-b='isCategoryFilterAB' :catalog-data='data')

            //- A/B Category filter update - RefinementSelections location changes based on device
            //- Because of flex layout issues duplicating this section and v-if for desktop & mobile was easier
            template(v-else)
              CatalogRefinementsSelections(v-if='$device.value.isSmall' :catalog-data='data')

              //- On Desktop we only want the toolbar to show when we have results
              template(v-if='$device.value.isDesktop')
                AlgoliaResults
                  template(#results)
                    CatalogToolbar(:is-category-filter-a-b='isCategoryFilterAB' :catalog-data='data')

              template(v-else)
                CatalogToolbar(:is-category-filter-a-b='isCategoryFilterAB' :catalog-data='data')

              CatalogRefinementsSelections(v-if='$device.value.isDesktop' :catalog-data='data')

            AlgoliaResults
              template(#results)
                AlgoliaResults(
                  v-bind='searchWrapperAttributes'
                  class='flex flex-wrap -mx-2'
                  :class='isCategoryFilterAB ? "lg:-mx-7" : "lg:-mx-3"'
                  data-testid='catalogResult'
                )
                  template(#result='{ result, index }')
                    div(
                      :key='result.objectID'
                      class='px-2 py-2 w-full xs:w-1/2 lg:py-0 lg:mb-5'
                      :class='isCategoryFilterAB ? "lg:w-1/4 lg:px-7 lg:pb-14" : "lg:w-1/3 lg:px-3"'
                    )
                      ProductListing(
                        :listing='result'
                        :is-lazy='index >= 6'
                        :data-cnstrc-item-id='result.productLineSlug'
                        :data-cnstrc-item-variation-id='result.skuSlug ? `${result.productLineSlug}_${result.skuSlug}` : undefined'
                        :data-cnstrc-item-name='result.productLineName'
                        @product-clicked='emitProductListClicked(result, index)'
                      )
                      CompareCheckbox(
                        v-if='data?.story?.content?.productCompareAttributeLists?.length > 0'
                        :listing='result'
                      )

              template(#empty)
                CatalogNoResults

            AlgoliaPagination

    AlgoliaResults
      CmsStoryblok(
        v-if='data.story?.content?.bottomSections'
        :content='data.story.content'
        section-key='bottomSections'
      )

    div(class='px-3 bg-white')
      div(class='lg:container')
        //- RECOMMENDED PRODUCTS
        CarouselProduct(v-if='$sitewideConfig.config.recommendedProductsEnabled' product-type='recommended')
        //- RECENTLY VIEWED
        CarouselProduct(product-type='recent')

  ComparePanel(v-if='data?.story?.content?.productCompareAttributeLists?.length > 0')
</template>

<script setup lang="ts">
import type { RouteLocationNormalized } from '#vue-router'

const catalogTypes = ['category', 'category-custom', 'brand', 'mmy', 'new-products', 'specials', 'search'] as const
const { $algolia, $storyblok, $sitewideConfig, $uiEvents, $scrollTo, $timing, $speedcurve } = useNuxtApp()
const fitmentStore = useFitmentStore()
const fitmentDisplayStore = useFitmentDisplayStore()
const fitmentFilters = useFitmentFilters()
const realtruckFilters = useRealtruckFilters()
const amplitude = useAmplitude()
const { getApiUrl, getImageUrl } = useUrls()
const { resolveTemplates } = useMetaTagTemplates()
const { translateTemplate } = useTemplateTranslator()
const route = useRoute()
const router = useRouter()
const type = getType(route) || ''
const slug = getSlug(type)
const isCategoryFilterAB = ref(false) // This test was never ran.  We'll need to wire this up post launch in Nuxt 3 so Amplitude can tweak this
const searchContext = $algolia.searchContexts.catalog
const scrollTopEl = ref()

const { data, error } = await useAsyncData('catalogPage', async () => {
  if (!type || type === 'search') {
    const data = polyfillData({})
    await updateSearch(data)
    return data
  }

  let timeTravel = route.query.timeTravel ? `?timeTravel=${route.query.timeTravel}` : ''
  let endpoint: ApiUrlKey

  if (type === 'specials' || type === 'new-products') endpoint = 'catalog'
  else if (type === 'category-custom') {
    endpoint = 'category'
    timeTravel = timeTravel.replace('?', '&')
  } else endpoint = type

  const url = getApiUrl(endpoint) + slug + timeTravel

  const timingApiKey = `${type}-api`

  $timing.start(timingApiKey)
  const catalogData = polyfillData(await $fetch(url))
  $timing.end(timingApiKey)

  // Gets the initial preview data (saved not published)
  const preview = await $storyblok.getPreviewStory<Story>()
  if (preview) catalogData.story = preview.story

  const timingAlgoliaKey = `${type}-algolia`
  $timing.start(timingAlgoliaKey)
  await updateSearch(catalogData)
  $timing.end(timingAlgoliaKey)

  // create empty selects and reposition optionals
  fitmentDisplayStore.setupFitment(catalogData.requiredFitmentOptions)

  return catalogData
})

if (error.value) {
  const isPageOutOfRange = error.value.message.includes('Page is out of range')
  if (error.value?.statusCode === 404 || isPageOutOfRange)
    throw createError({ statusCode: 404, fatal: import.meta.client })
  throw createError({ statusCode: 500, message: error.value.message, fatal: true })
}

// Determine if we shouldHideProducts by A/B Test
const shouldHideProducts = ref(data.value.story?.content?.hideProducts === 'true')
if (!$storyblok.isEditing.value && data.value.story?.content?.hideProductsExperimentId) {
  amplitude.onReady({
    experimentId: data.value.story.content.hideProductsExperimentId,
    callback: (variant) => {
      // If we are not in treatment, shouldHideProducts should be the opposite of what is selected
      if (variant.value === 'treatment') return
      shouldHideProducts.value = !shouldHideProducts.value
    },
  })
}

onBeforeMount(() => {
  $speedcurve.track('Catalog Page')
})

onMounted(async () => {
  // This function will check if the fitment modal needs to open because of the sameDayFacet
  tryFitmentModalOpen()

  // refill selects (this would run twice on first hit since app.vue initSelects too - meh it's cached)
  fitmentDisplayStore.initSelects()

  emitProductListViewed()
  emitProductListFiltered()
})

// if they are going to a non-catalog (or the search page) page, re-setup the fitment selects to their defaults and refill
const unRegisterRouteGuard = router.afterEach((to) => {
  const toType = getType(to)
  if (!toType || toType === 'search') {
    fitmentDisplayStore.setupFitment()
    fitmentDisplayStore.initSelects()
  }
})

onUnmounted(() => {
  unRegisterRouteGuard()
})

watch(
  () => route.query,
  async (toQuery, fromQuery) => {
    await updateSearch(data.value)

    // This function will check if the fitment modal needs to open because of the sameDayFacet
    // We don't want to get in a forceOpen loop so we will only try to open the fitment modal
    // when we don't have sameDayShipping in the url and we didn't come from a url will a mod open
    if (!toQuery.sameDayShipping && fromQuery.mod) tryFitmentModalOpen()

    scrollTop()

    emitProductListViewed()
    emitProductListFiltered()
  }
)

const page = computed(() => $algolia.searchContexts.catalog.state.value.currentPage)

const disableH1 = computed(() => {
  return data.value?.story?.content?.disableH1 === 'true'
})

const h1 = computed(() => {
  return data.value?.story?.content?.h1 || data.value?.h1 || catalogName.value
})

const catalogName = computed(() => {
  return route.name?.toString().includes('category-custom') && $storyblok.isPreview.value
    ? `${data.value.story?.content?.facetValue || ''} ${data.value.title}`
    : data.value.title
})

const hideProducts = computed(() => {
  // Default Value
  if ($storyblok.isEditing.value) return data.value.story?.content?.hideProducts === 'true'
  return shouldHideProducts.value
})

const searchWrapperAttributes = computed(() => {
  const obj = {
    ...(type === 'search' ? { 'data-cnstrc-search': '' } : { 'data-cnstrc-browse': '' }),
    'data-cnstrc-num-results': searchContext.state.value.resultCount,
  }
  return obj
})

const isNoIndex = route.name?.toString() === 'search' || data.value?.story?.content.disableSeoIndex === 'true'

const socialImageUrl = (() => {
  if (data.value.story?.content?.shareImage?.filename)
    return $storyblok.getNormalizedAsset(data.value.story.content.shareImage.filename, 1920)?.src

  if ($algolia.searchContexts.catalog.state.value.results.length > 0) {
    const [result] = $algolia.searchContexts.catalog.state.value.results

    return getImageUrl(result.image.key, result.image.filename, 800, 600, 80, 'fff')
  }
})()

const metaTemplates = getMetaTemplates()
const metaVariables = {
  ...(['category-custom', 'category'].includes(type) && { CATEGORY: catalogName.value }),
  ...(type === 'brand' && { BRAND: data.value.name }),
}

useMetaTags({
  title: computed(
    () => (page.value > 1 ? `Page ${page.value} - ` : '') + translateTemplate(metaTemplates.title, metaVariables)
  ),
  description: translateTemplate(metaTemplates.description, metaVariables),
  isNoIndex,
  ...(socialImageUrl && { socialImageUrl }),
})

function scrollTop() {
  // Only scroll if we have a top defined
  if (!scrollTopEl.value) return

  // Scrolls to top (top defined by a Ref) so long as we have scrolled passed it.
  const rect = scrollTopEl.value.getBoundingClientRect()
  const top = rect.top + window.pageYOffset

  if (window.pageYOffset > top) $scrollTo(scrollTopEl.value)
}

function emitProductListViewed() {
  const { indexName, queryID, results } = $algolia.searchContexts.catalog.state.value

  if (results.length === 0) return
  $uiEvents.$emit('productListViewed', { results, indexName, queryID })
}

function emitProductListFiltered() {
  const { indexName, activeRefinements, activeRefinementCount, results } = $algolia.searchContexts.catalog.state.value

  if (activeRefinementCount <= 0 || results.length === 0) return

  $uiEvents.$emit('productListFiltered', {
    filters: activeRefinements,
    sort: indexName,
    products: results,
  })
}

function emitProductListClicked(algoliaObj, position) {
  const { indexName, queryID } = $algolia.searchContexts.catalog.state.value
  $uiEvents.$emit('productListClicked', {
    algoliaObj,
    position,
    indexName,
    queryID,
  })
}

function tryFitmentModalOpen() {
  if (
    !$sitewideConfig.config.sameDayShippingEnabled ||
    !route.query.sameDayShipping ||
    fitmentDisplayStore.hasFullFitment
  )
    return

  fitmentDisplayStore.showFitmentModal({ isSameDayShippingMode: true })
}

// gets the site configured metas and overrides with storyblok as needed
function getMetaTemplates() {
  const storyblokMetaTemplates = {
    ...(data.value.story?.content?.metaTitle && {
      title: data.value.story.content.metaTitle,
    }),
    ...(data.value.story?.content?.metaTitleFitment && {
      titleFitment: data.value.story.content.metaTitleFitment,
    }),
    ...(data.value.story?.content?.metaDescription && {
      description: data.value.story.content.metaDescription,
    }),
    ...(data.value.story?.content?.metaDescriptionFitment && {
      descriptionFitment: data.value.story.content.metaDescriptionFitment,
    }),
    ...(data.value.story?.content?.metaDescriptionMake && {
      descriptionMake: data.value.story.content.metaDescriptionMake,
    }),
    ...(data.value.story?.content?.metaDescriptionModel && {
      descriptionModel: data.value.story.content.metaDescriptionModel,
    }),
    ...(data.value.story?.content?.metaDescriptionYear && {
      descriptionYear: data.value.story.content.metaDescriptionYear,
    }),
  }

  return resolveTemplates(type, storyblokMetaTemplates)
}

async function updateSearch(catData: any) {
  // Clear out all filters to begin with
  searchContext.clearFilters()

  // We don't show accessories so set that
  searchContext.addFilters('isAccessory', 'false')

  // We don't show replacement parts on brand pages
  if (route.name === 'brand' || route.name === 'brand-mmy') searchContext.addFilters('isReplacementPart', 'false')

  // Set Facets for current category. Facets must be defined for a refinement to be applied.
  setFacetAttributes(catData)

  // Set filters based upon current fitment
  fitmentFilters.update(searchContext)

  // NonTransactional Needs to only show RT Products
  if ($sitewideConfig.config.nonTransactionalEnabled) realtruckFilters.addFilter(searchContext)

  // Set filters based upon route information
  setRouteFilters()

  // Set filters if on Facet Landing Page.
  // We can support any number of facet names and values.
  // The values come from the story so we need to make sure that we are on a category custom page,
  // Otherwise they could set these values on a normal category which would have unintended side effects.
  if (route.name?.toString().includes('category-custom') && catData.story) {
    // Extract an array of facets from content
    const facets = Object.entries(catData.story.content)
      .filter(([key, value]) => key.startsWith('facetName') && value)
      .map(([facetKey, facetKeyValue]) => {
        const facetNumber = facetKey.replace('facetName', '')
        const facetValue = catData.story.content[`facetValue${facetNumber}`]
        const values = facetValue
          .split(',')
          .map((v) => v.trim())
          .filter((v) => v)
        if (values.length > 0) searchContext.addFilters(facetKeyValue, values) // split comma-separated values only in facetValue
        return facetKeyValue
      })

    // Remove the facet set for the custom category
    if ($storyblok.isPreview.value) {
      searchContext.state.value.searchParameters.facets = searchContext.state.value.searchParameters.facets.filter(
        (facet) => !facets.includes(facet)
      )
    }
  }

  // Set state based upon the route query
  searchContext.syncFromRoute(route)

  // We need to make sure we can show results width the sameDayShipping facet applied.
  // If we can't show it, then we need to make sure we clear out that refinement after we sync'd our state from the route.
  if (
    $sitewideConfig.config.sameDayShippingEnabled &&
    searchContext.isRefined('sameDayShipping') &&
    !fitmentDisplayStore.hasFullFitment
  ) {
    searchContext.clearRefinements('sameDayShipping')
  }

  // trigger the actual search to update the data.
  await searchContext.search()
}

function setFacetAttributes(catData: any) {
  const lastLevel = getLastCategoryLevel(route)
  let targetLevel = 0

  if (lastLevel !== undefined) targetLevel = lastLevel + 1
  else if ($sitewideConfig.config.useSecondLevel) targetLevel = 1

  searchContext.state.value.searchParameters.facets = [
    ...catData.facets.map(({ facet }) => facet),
    `categories.lvl${targetLevel}.text`,
    `categories.lvl${targetLevel}.slug`,
  ]

  // the showSameDayShippingFacet checks if the site has it enabled and if the page you are on requests it as a facet.
  if (catData.showSameDayShippingFacet) searchContext.state.value.searchParameters.facets.push('sameDayShipping')
}

function setRouteFilters() {
  if (!route.params) return

  const lastLevel = getLastCategoryLevel(route)

  if (lastLevel !== undefined) {
    const arr = []

    for (let i = 0; i <= lastLevel; i++) arr.push(route.params[`lvl${i}`])

    searchContext.addFilters(`categories.lvl${lastLevel}.slug`, arr.join('|'))
  }

  if (route.name?.toString().includes('brand')) searchContext.addFilters('brandSlug', route.params.brandSlug)

  if (route.name?.toString().includes('specials')) searchContext.addFilters('hasSpecial', 'true')

  if (route.name?.toString().includes('new-products')) searchContext.addFilters('isNew', 'true')
}

function getType(route: RouteLocationNormalized): (typeof catalogTypes)[number] | undefined {
  const baseRouteName: any = route.matched[0].name?.toString().replace('-mmy', '') || ''
  if (catalogTypes.includes(baseRouteName)) return baseRouteName
}

function getSlug(type?: string) {
  switch (type) {
    case 'category-custom':
    case 'category': {
      const arr = []
      for (let i = 0; i <= (getLastCategoryLevel(route) || 0); i++) arr.push(route.params[`lvl${i}`])

      // If we are in preview mode, we don't want to hit the cat endpoint with a customFacet because if the page is not published, it will 404.
      if (route.params.facetSlug && !$storyblok.isPreview.value) arr.push(`?customFacet=${route.params.facetSlug}`)

      return arr.join('/')
    }
    case 'brand':
      return route.params.brandSlug

    case 'mmy': {
      const mmy = [route.params.makeSlug]
      if (route.params.modelSlug) mmy.push(route.params.modelSlug)
      if (route.params.year) mmy.push(route.params.year)
      return mmy.join('/')
    }
    case 'new-products':
    case 'specials':
      // We can just return the type as the slug for specials and new-products
      return type

    default:
      // If its not one of these then we need to return nothing
      return null
  }
}

function polyfillData(data: any) {
  const obj = data
  const lastCategoryLevelInUrl = getLastCategoryLevel(route)

  obj.type = type

  obj.category = {
    facetLevel: lastCategoryLevelInUrl !== undefined ? lastCategoryLevelInUrl + 1 : 0,
    showAsFacet: false,
  }

  obj.showSameDayShippingFacet = $sitewideConfig.config.sameDayShippingEnabled
  obj.title = data.name // used in queries too!?
  obj.facets = data.facets || []

  // If we are a Non Transactional site we need to remove the priceRange facet and the specials facet
  if ($sitewideConfig.config.nonTransactionalEnabled) {
    obj.facets = obj.facets.filter(({ facet }) => facet !== 'priceRange' && facet !== 'specials')
  }

  // Check if the sameDayShipping Facet on the current page
  const sameDayShippingFacetIndex = obj.facets.findIndex(({ facet }) => facet === 'sameDayShipping')
  if (sameDayShippingFacetIndex > -1) {
    // sameDayShipping is a special facet where we only want to show it if its enabled and show it in our special facet component
    // so we need to make sure that we don't ever show it as a standard facet, even if the api tells us to which is why
    // we always need to splice it from the facets array if it exists.
    obj.facets.splice(sameDayShippingFacetIndex, 1)
  }

  if (type === 'brand') {
    if ($sitewideConfig.config.useSecondLevel) obj.category.facetLevel = 1
  }

  if (type === 'mmy') {
    obj.title = 'Parts &amp; Accessories'

    if ($sitewideConfig.config.useSecondLevel) obj.category.facetLevel = 1
  }

  if (type === 'specials') {
    obj.title = 'Deals'

    obj.category.showAsFacet = true

    const levelText = $sitewideConfig.config.useSecondLevel ? 'categories.lvl1.text' : 'categories.lvl0.text'

    obj.facets.push({ facet: levelText, text: 'Category' })
  }

  if (type === 'new-products') {
    obj.title = 'New Products'

    obj.category.showAsFacet = true

    const levelText = $sitewideConfig.config.useSecondLevel ? 'categories.lvl1.text' : 'categories.lvl0.text'

    obj.facets.push({ facet: levelText, text: 'Category' })
  }

  if (type === 'search') {
    const query = route.query.q?.toString().replace(/[^\da-z-+ ]+/gi, '')
    obj.title = `Search results for "${query || ''}"`

    obj.category.showAsFacet = true

    const levelText = $sitewideConfig.config.useSecondLevel ? 'categories.lvl1.text' : 'categories.lvl0.text'

    obj.facets = [
      { facet: levelText, text: 'Category' },
      { facet: 'brand', text: 'Brand' },
    ]

    // Only add Price Range if we are a Transactional site
    if (!$sitewideConfig.config.nonTransactionalEnabled) obj.facets.push({ facet: 'priceRange', text: 'Price Range' })
  }

  return obj
}
</script>
