<template lang="pug">
div(class='fixed inset-0 z-[100] pointer-events-none')
  transition(
    enter-active-class='transition-opacity ease-linear duration-200'
    enter-from-class='opacity-0'
    enter-to-class='opacity-100'
    leave-active-class='transition-opacity ease-linear duration-200'
    leave-from-class='opacity-100'
    leave-to-class='opacity-0'
  )
    div(v-if='showFilterMenu' class='fixed inset-0 pointer-events-auto' @click='closeFilterMenu')
      div(class='w-full h-full bg-black opacity-80')

  transition(
    enter-active-class='right-0 transition ease-in-out duration-200 transform'
    enter-from-class='right-0 translate-x-full'
    enter-to-class='right-0'
    leave-active-class='right-0 transition ease-in-out duration-200 transform'
    leave-from-class='right-0'
    leave-to-class='translate-x-full'
  )
    aside(v-show='showFilterMenu' class='absolute w-[404px] md:w-[480px] pointer-events-auto' style='right: 0')
      CatalogFilterSlideMenuFilterMenu(
        v-if='menuType === "filter"'
        ref='filterMenu'
        :filter-sub-menu='filterSubMenu'
        :catalog-data='catalogData'
        @close-filter-menu='closeFilterMenu'
        @close-filter-sub-menu='closeFilterSubMenu'
      )
      CatalogFilterSlideMenuSortMenu(v-else-if='menuType === "sort"')

      button(type='button' class='absolute top-4 cursor-pointer right-0 -translate-x-full' @click='closeFilterMenu')
        xIcon(aria-label='close' class='w-4 h-4 text-black fill-current stroke-current')

      div(class='absolute p-6 pb-12 bottom-0 right-0 w-[404px] md:w-[480px] bg-white border-t border-gray-light')
        div(class='flex items-center justify-around')
          button(
            v-if='menuType === "filter"'
            type='button'
            class='w-full mx-3 bg-gray px-8 py-3 text-light text-center uppercase font-bold'
            @click='clearRefinements'
          ) Clear All

          button(
            type='button'
            class='w-full mx-3 bg-action px-8 py-3 text-light text-center uppercase font-bold'
            @click='closeFilterMenu'
          )
            span(v-if='menuType === "filter"') Apply
            span(v-else) Close
</template>

<script setup lang="ts">
import xIcon from '@/assets/x.svg?component'
const emit = defineEmits(['onToggle', 'closeFilterSubMenu'])
const { $algolia } = useNuxtApp()
const filterMenu = ref() // template ref
const route = useRoute()

const {
  showFilterMenu = false,
  filterSubMenu,
  menuType = 'filter',
  catalogData,
} = defineProps<{
  showFilterMenu: boolean
  filterSubMenu?: string
  menuType?: string
  catalogData: any
}>()

function closeFilterMenu() {
  // Close all sub menus
  if (menuType === 'filter') filterMenu.value.closeFilterSubMenu()

  emit('onToggle', false)
}

function closeFilterSubMenu() {
  emit('closeFilterSubMenu')
}

function clearRefinements() {
  $algolia.searchContexts.catalog.clearRefinements()

  const newRoute: any = {
    name: route.name,
    params: {
      ...route.params,
    },
    query: {
      ...$algolia.searchContexts.catalog.createRouteQuery(),
    },
  }

  navigateTo(newRoute)

  closeFilterMenu()
}

useHead({
  htmlAttrs: {
    style: () => {
      return showFilterMenu ? 'overflow: hidden' : ''
    },
  },
})
</script>
